<template>
  <Head>
    <title v-if="content && content.title !== null">{{ content.title }}</title>
    <title v-else>
      Best Online Coaching for Neet, IIT-JEE, CBSE,CA and Junior IAS Initiative
      - VB Live
    </title>
    <meta
      v-if="content && content.meta_description !== null"
      name="description"
      :content="content.meta_description"
    />
    <meta
      v-else
      name="description"
      content="VB Live is the Best Online Coaching Platform for NEET, IIT-JEE, CBSE, Junior IAS Initiative and CA Foundation. We provides all Courses from Class 6 to Class 12 with Experienced Faculty."
    />
    <meta
      v-if="content && content.meta_keywords !== null"
      name="keywords"
      :content="content.meta_keywords"
    />
    <meta
      v-else
      name="keywords"
      content="online training, courses online, online training courses, online tuition, online tuition classes for class 10, best online tuition for class 10, Best online tuition, best courses online, best online training, online tuition classes for class 12, best online tuition for class 12, best online coaching, ias online classes"
    />
  </Head>
  <div class="about">
    <div
      class="inner-banner"
      v-if="content && content.banner_image != null"
      :style="{ backgroundImage: 'url(' + content.banner_image + ')' }"
    >
      <div class="container">
        <div class="row _content align-items-center">
          <div class="col-md-4">
            <h2 v-if="content">{{ content.title }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div
      class="inner-banner"
      v-else
      style="background: url(../assets/banner-img.png)"
    >
      <div class="container">
        <div class="row _content align-items-center">
          <div class="col-md-4">
            <h2 v-if="content">{{ content.title }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container intro-content">
      <div class="row aign-items-center">
        <div class="col-md-4">
          <img
            v-if="content && content.logo != null"
            :src="content.logo"
            alt="VBLive"
          />
          <!-- <img v-else src="../assets/vb-cbse.png" /> -->
        </div>
        <div v-if="content" v-html="content.content" class="col-md-8"></div>
      </div>
    </div>
    <div
      v-if="
        content &&
        (content.section_one_title_one !== null ||
          content.section_one_title_two !== null)
      "
      class="container"
    >
      <div class="section-box">
        <div class="primary-header">
          <h3>
            What<span class="highlite"> makes </span>us
            <span class="highlite"> Different </span>?
          </h3>
        </div>
        <div class="row _main">
          <div
            v-if="content && content.section_one_title_one !== null"
            class="col-md-6"
          >
            <div class="row">
              <div class="col-md-3">
                <div class="icon-wrap">
                  <img
                    v-if="
                      content && content.section_one_title_one_image != null
                    "
                    :src="content.section_one_title_one_image"
                    alt=""
                  />
                  <img v-else src="../assets/faculty.png" alt="" />
                </div>
              </div>
              <div class="col-md-9">
                <h4 v-if="content">{{ content.section_one_title_one }}</h4>
                <p v-if="content">
                  {{ content.section_one_title_one_description }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="content && content.section_one_title_two !== null"
            class="col-md-6"
          >
            <div class="row">
              <div class="col-md-3">
                <div class="icon-wrap">
                  <img
                    v-if="
                      content && content.section_one_title_two_image != null
                    "
                    :src="content.section_one_title_two_image"
                    alt=""
                  />
                  <img v-else src="../assets/space.png" alt="" />
                </div>
              </div>
              <div class="col-md-9">
                <h4 v-if="content">{{ content.section_one_title_two }}</h4>
                <p v-if="content">
                  {{ content.section_one_title_two_description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="aboutTestimonials.length > 0" class="leaders-slide">
      <div class="container">
        <div class="primary-header">
          <h3><span class="highlite">What Our Bodhians leaders Say</span></h3>
        </div>
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              v-for="(aboutTestimonial, testimonialIndex) in aboutTestimonials"
              :key="testimonialIndex"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              :data-bs-slide-to="testimonialIndex"
              :class="testimonialIndex === 0 ? 'active' : ''"
              :aria-current="testimonialIndex === 0 ? 'true' : ''"
              :aria-label="'Slide' + aboutTestimonial.id"
            ></button>
          </div>
          <div class="carousel-inner">
            <div
              v-for="(aboutTestimonial, testimonialIndex) in aboutTestimonials"
              :key="testimonialIndex"
              class="carousel-item"
              :class="testimonialIndex === 0 ? 'active' : ''"
            >
              <div class="row">
                <div class="col-md-4">
                  <div class="img-wrap">
                    <img
                      v-if="aboutTestimonial && aboutTestimonial.image != null"
                      :src="aboutTestimonial.image"
                      alt=""
                    />
                  </div>
                  <h5 v-if="aboutTestimonial">{{ aboutTestimonial.name }}</h5>
                  <h6 v-if="aboutTestimonial">
                    {{ aboutTestimonial.designation }}
                  </h6>
                </div>
                <div class="col-md-8">
                  <div class="slide-text">
                    <h3 v-if="aboutTestimonial">
                      {{ aboutTestimonial.title }}
                    </h3>
                    <p v-if="aboutTestimonial">
                      {{ aboutTestimonial.content }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            v-show="aboutTestimonials.length > 1"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            v-show="aboutTestimonials.length > 1"
            class="carousel-control-next"
            type="button"
            id="nextTestimonialButton"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            ref="nextButton"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
    <div class="review-wrapper">
      <div class="experience-wrapper container">
        <Testimonial
          v-if="sortedTestimonials.length > 0"
          :sortedTestimonials="sortedTestimonials"
        />
      </div>
    </div>
    <CareerCounselling />
  </div>
</template>

<script>
import CommonService from "@/services/CommonService";
import Testimonial from "@/components/Testimonial.vue";
import CareerCounselling from "@/components/CareerCounselling.vue";
import { Head } from "@vueuse/head";

export default {
  name: "about",
  components: {
    Head,
    Testimonial,
    CareerCounselling,
  },
  data() {
    return {
      content: null,
      sortedTestimonials: [],
      aboutTestimonials: [],
    };
  },
  created() {
    this.fetchContent();
    this.fetchTestimonials();
  },
  mounted() {
    this.$nextTick(() => {
      setInterval(() => {
        if (this.aboutTestimonials.length > 1) {
          // Note:- Use either one method
          // # Method 1:-
          // let nextButton = document.querySelector("#nextTestimonialButton");
          // // console.log(nextButton);
          // if (nextButton !== null) {
          //   nextButton.click();
          // }
          // #Method 2:-
          if (this.$refs.nextButton !== null) {
            this.$refs.nextButton.click();
          }
        }
      }, 5000);
    });
  },
  methods: {
    fetchContent() {
      let params = {
        slug: "about",
      };
      CommonService.getContentPage(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.content = response.data.content;
            this.aboutTestimonials = response.data.about_testimonials;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchTestimonials() {
      CommonService.getTestimonials()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            let testimonials = response.data.testimonials;
            let count = testimonials.length;
            let j = 0;
            let k = 0;
            for (let i = 1; i <= count; i++) {
              j = k;
              k = k + 2;
              if (j >= count) {
                break;
              } else {
                this.sortedTestimonials.push(testimonials.slice(j, k));
              }
            }
            // console.log(this.sortedTestimonials);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/about.scss";
</style>
