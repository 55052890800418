<template>
  <div class="career-wrapper">
    <div class="container text-center">
      <img src="../assets/cta-line.svg" alt="" />
      <h1>Get free counselling from our experts</h1>
      <p>Forward your details and our experts will get in touch</p>
      <div class="cta-wrap">
        <button
          class="btn cta-primary"
          data-bs-target="#myModal"
          aria-haspopup="true"
          data-bs-toggle="modal"
          ref="openModal"
          @click="refreshForm(), loadStandards()"
        >
          Book a free demo class
        </button>
        <!-- <button
          class="btn cta-primary"
          data-bs-target="#myModal"
          aria-haspopup="true"
          data-bs-toggle="modal"
          ref="openModal"
          @click="refreshForm(), loadStandards()"
        >
          Book now
        </button> -->
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="myModal"
    tabindex="-1"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabel">
            <!-- Book your FREE Online Counselling Section -->
            Knowledge and care from VBLive's best teachers
          </h5>
          <button
            type="button"
            class="btn-close"
            ref="closeModal"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="refreshForm"
          ></button>
        </div>
        <form @submit.prevent="saveCounselling">
          <div class="modal-body">
            <div class="col-md-12 mb-3">
              <label class="form-label">Parent Name <span>*</span></label>
              <input
                type="text"
                placeholder="Parent Name"
                class="form-control"
                v-model="your_name"
              />
              <div v-if="errors && errors.your_name" class="error-msg">
                {{ errors.your_name[0] }}
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label class="form-label">Email ID <span>*</span></label>
              <input
                type="email"
                placeholder="Email ID"
                class="form-control"
                v-model="email"
              />
              <div v-if="errors && errors.email" class="error-msg">
                {{ errors.email[0] }}
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label class="form-label">Mobile Number <span>*</span></label>
              <input
                type="text"
                placeholder="Mobile Number"
                class="form-control"
                v-model="phone"
              />
              <div v-if="errors && errors.phone_number" class="error-msg">
                {{ errors.phone_number[0] }}
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label class="form-label">Inquiring For <span>*</span></label>
              <Multiselect
                v-model="inquiringForOptions.value"
                v-bind="inquiringForOptions"
              />
              <div v-if="errors && errors.inquiring_for" class="error-msg">
                {{ errors.inquiring_for[0] }}
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label class="form-label">Student Name <span>*</span></label>
              <input
                type="text"
                placeholder="Student Name"
                class="form-control"
                v-model="name"
              />
              <div v-if="errors && errors.name" class="error-msg">
                {{ errors.name[0] }}
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label class="form-label">Select Standard <span>*</span></label>
              <Multiselect
                v-model="standardOptions.value"
                v-bind="standardOptions"
              />
              <div v-if="errors && errors.standard_id" class="error-msg">
                {{ errors.standard_id[0] }}
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label class="form-label">Got any questions for us?</label>
              <textarea
                class="form-control"
                v-model="questions"
                rows="3"
                placeholder="Enter questions"
              >
              </textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              :disabled="isSubmitting == true"
              class="btn cta-primary"
            >
              Schedule a call back
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import CommonService from "@/services/CommonService";
import Multiselect from "@vueform/multiselect";
export default {
  name: "CareerCounselling",
  components: {
    Multiselect,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      your_name: "",
      name: "",
      email: "",
      phone: "",
      uestions: "",
      errors: {},
      isSubmitting: false,
      inquiringForOptions: {
        options: [
          {
            value: "Myself",
            label: "Myself",
          },
          {
            value: "Son",
            label: "Son",
          },
          {
            value: "Daughter",
            label: "Daughter",
          },
          {
            value: "Brother",
            label: "Brother",
          },
          {
            value: "Sister",
            label: "Sister",
          },
          {
            value: "Relative",
            label: "Relative",
          },
          {
            value: "Friend",
            label: "Friend",
          },
        ],
        placeholder: "Inquiring For",
        canDeselect: false,
        searchable: true,
      },
      standardOptions: {
        value: null,
        options: [],
        placeholder: "Select Standard",
        canDeselect: false,
        loading: true,
        // required: true,
        searchable: true,
      },
    };
  },
  watch: {
    showModal(newVal) {
      if (newVal === true) {
        this.$refs.openModal.click();
      } else {
        this.$refs.closeModal.click();
        this.$emit("hideCounsellingModal", false);
      }
    },
  },
  // created() {
  //   this.loadStandards();
  // },
  methods: {
    loadStandards() {
      CommonService.getFeaturedStandards()
        .then((response) => {
          this.standardOptions.loading = false;
          if (response.data.status === "SUCCESS") {
            this.standardOptions.options = []; // to clear previously loaded options
            let standards = response.data.standards;
            if (standards.length > 0) {
              standards.map((standard) => {
                let options = {
                  value: standard.id,
                  label: standard.standard_title,
                };
                this.standardOptions.options.push(options);
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveCounselling() {
      const params = {
        your_name: this.your_name,
        name: this.name,
        email: this.email,
        phone_number: this.phone,
        inquiring_for: this.inquiringForOptions.value,
        standard_id: this.standardOptions.value,
        questions: this.questions,
        type: "COUNSELLING",
      };
      this.isSubmitting = true;
      CommonService.saveCounselling(params)
        .then((response) => {
          this.isSubmitting = false;
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            this.standardOptions.value = null;
            this.inquiringForOptions.value = null;
            this.your_name = "";
            this.name = "";
            this.email = "";
            this.phone = "";
            this.questions = "";
            this.errors = {};
            this.$refs.closeModal.click();
            this.$emit("hideCounsellingModal", false);
          }
          if (response.data.status === "ERROR") {
            this.errors = response.data.message || {};
          }
        })
        .catch((error) => {
          this.isSubmitting = false;
          console.error(error);
        });
    },
    refreshForm() {
      this.standardOptions.value = null;
      this.name = "";
      this.email = "";
      this.phone = "";
      this.errors = {};
      this.$emit("hideCounsellingModal", false);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.modal-content {
  border-radius: 16px;
  select,
  textarea,
  input {
    border: 1px solid #9dd8d9 !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    margin-bottom: 10px !important;
  }
  .multiselect-search > input {
    border: none !important;
  }
}
</style>
